import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';

class CachedReportModelSchema extends NovaSchema {
  constructor() {
    super('cachedReport');
    this.attributes = {
      id: new SchemaAttribute(),
      tenantId: new SchemaAttribute(),
      type: new SchemaAttribute(),
      timeframe: new SchemaAttribute(),
      data: new SchemaAttribute(),
      createdAt: new SchemaAttribute(new Date().toISOString()),
      updatedAt: new SchemaAttribute(new Date().toISOString()),
    };
  }
}

export const CachedReportSchema = new CachedReportModelSchema();
