import { CachedReportSchema } from './schema/cached-report.js';
import { NovaModel } from './nova-model.js';

export default class CachedReport extends NovaModel {
  constructor(base = {}) {
    super('cachedReport', base);
  }

  getSchema() {
    return CachedReportSchema;
  }
}
